import { Component } from "@angular/core";

import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

declare var TestFairy: any;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html"
})
export class AppComponent {
  TestFairy: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // let status bar overlay webview
      /*this.statusBar.overlaysWebView(true);

      // set status bar to white
      this.statusBar.backgroundColorByHexString("#204F4E");
      this.statusBar.styleLightContent();
      this.splashScreen.hide();*/
    });
  }
}
