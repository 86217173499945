import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { environment } from "../environments/environment";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GuestGuardService } from "./shared/guard/guest";
import { ServiceWorkerModule } from "@angular/service-worker";
import { MustMatchDirective } from "./_helpers/must-match.directive";
import { UserService } from './shared/service/user.service';
import { ApiconfigService } from './shared/service/apiconfig.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BoardsService } from './shared/service/boards.service';
import { LoaderService } from './shared/service/loader.service';
import { HttpErrorInterceptor } from './http-error.interceptor';

@NgModule({
  declarations: [AppComponent, MustMatchDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    GuestGuardService,
    UserService,
    ApiconfigService,
    BoardsService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
