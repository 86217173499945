import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root"
})
export class GuestGuardService implements CanActivate {
  constructor(public authService: AuthService, public router: Router, private activatedRoute: ActivatedRoute) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isLoggedIn == true) {
      if (next.queryParams.code != null) {
        this.router.navigate(["/dashboard/tabs/locate-board"], { queryParams: { 'code': next.queryParams.code } });
      } else {
        this.router.navigate(["dashboard/tabs"]);
      }

    }
    return true;
  }
}
