import { Injectable } from "@angular/core";
import { ApiconfigService } from './apiconfig.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class BoardsService {
  private collectionName: string = "boards";

  constructor(private apiConfig: ApiconfigService, private http: HttpClient) { }

  searchBoardByCode(code: string) {
    return this.http.get(this.apiConfig.url + "/board-search/" + code, this.apiConfig.headers);
  }
  searchBoardByCode2(code: string) {
    return this.http.get(this.apiConfig.url + "/board-search2/" + code, this.apiConfig.headers);
  }
  attendBoard(boardId: string) {
    return this.http.post(this.apiConfig.url + "/participant/board/attend", { "board_id": boardId }, this.apiConfig.headers);
  }
  registerAndAttendBoard(name: string, boardId: string) {
    return this.http.post(this.apiConfig.url + "/participant/board/register-and-attend", { "board_id": boardId, "name": name }, this.apiConfig.headers);
  }
  saveCard(data) {
    return this.http.post(this.apiConfig.url + "/add-participant-card", data, this.apiConfig.headers);
  }

  getAllAttendedBoard(page: number) {
    return this.http.get(this.apiConfig.url + "/participant/board", this.apiConfig.headers);
  }

  getBoardDetails(id: number) {
    return this.http.get(this.apiConfig.url + `/participant/board-details/${id}`, this.apiConfig.headers);
  }

  deleteCard(id: number) {
    //delete-participant-card/{id}
    return this.http.get(this.apiConfig.url + `/delete-participant-card/${id}`, this.apiConfig.headers);
  }

  getCardDetails(id) {
    return this.http.get(this.apiConfig.url + `/get-participant-card/${id}`, this.apiConfig.headers);
  }
  updateCard(data) {
    return this.http.post(this.apiConfig.url + `/edit-participant-card`, data, this.apiConfig.headers);
  }

  getPollCards(partipant_board_id) {
    return this.http.get(this.apiConfig.url + `/participant/poll/all-card/${partipant_board_id}`, this.apiConfig.headers);
  }
  deleteBoard(partipant_board_id) {
    return this.http.get(this.apiConfig.url + `/participant/attend-board/delete/${partipant_board_id}`, this.apiConfig.headers);
  }

  submitPoll(data, board_id) {
    return this.http.post(this.apiConfig.url + `/participant/poll/submit/${board_id}`, data, this.apiConfig.headers);
  }
  updateParticipant(name) {
    return this.http.post(this.apiConfig.url + `/update/participant`, { name: name }, this.apiConfig.headers);
  }
}
