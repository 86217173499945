import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { GuestGuardService } from "./shared/guard/guest";

const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: "login",
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [GuestGuardService]
  },
  {
    path: "search-board",
    loadChildren: './search-board/search-board.module#SearchBoardPageModule',
    canActivate: [GuestGuardService]
  },
  {
    path: "help",
    loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule),
    canActivate: [GuestGuardService]
  },
  {
    path: "register",
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule),
    canActivate: [GuestGuardService]
  },
  { path: "", redirectTo: "/search-board", pathMatch: "full" },
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'activate/:token', loadChildren: () => import('./verify-account/verify-account.module').then(m => m.VerifyAccountPageModule) },
  { path: 'password/reset/:token', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule) },
  { path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpPageModule) },
  // { path: 'search-board', loadChildren: './search-board/search-board.module#SearchBoardPageModule' },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
