import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiconfigService {  
  //private baseUrl: string = "http://localhost/sididev-api/public/api/v1";
  //private baseUrl: string = "https://api.sididev.ch/api/v1";
  private baseUrl: string = "https://api.wiisdoo.com/api/v1";
  private httpHeaders: HttpHeaders;
  constructor() {

  }
  get headers() {
    const user = JSON.parse(localStorage.getItem("authuser"));
    var settings;
    if (user) {
      settings = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + user.token
      };
    } else {
      settings = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept': 'application/json'
      };
    }
    this.httpHeaders = new HttpHeaders(settings);
    return {
      headers: this.httpHeaders
    };
  }
  get url() {
    return this.baseUrl;
  }
}
