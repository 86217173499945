import { Injectable, NgZone } from "@angular/core";
import { User, Roles } from "../model/user";
// import { AngularFireAuth } from "@angular/fire/auth";
// import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  userData: any; // Save logged in user data

  constructor(
    // public afs: AngularFirestore, // Inject Firestore service
    // public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private navController: NavController,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    // this.afAuth.authState.subscribe(user => {
    //   //this.setOrDestroySession(user);
    // });
  }

  // Sign up with email/password
  // SignUp(email, password) {
  //   return this.afAuth.auth
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(result => {
  //       /* Call the SendVerificationMail() function when new user sign 
  //       up and returns promise */
  //       this.SendVerificationMail();
  //       this.SetUserData(result.user);
  //     })
  //     .catch(error => {
  //       window.alert(error.message);
  //     });
  // }

  // Send email verification when new user sign up
  // SendVerificationMail() {
  //   return this.afAuth.auth.currentUser
  //     .sendEmailVerification()
  //     .then(() => {
  //       this.router.navigate(["dashboard/verify-email"]);
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  // }

  // Reset Forgot password
  // ForgotPassword(passwordResetEmail) {
  //   return this.afAuth.auth
  //     .sendPasswordResetEmail(passwordResetEmail)
  //     .then(() => {
  //       window.alert("Password reset email sent, check your inbox.");
  //     })
  //     .catch(error => {
  //       window.alert(error);
  //     });
  // }

  // Returns true when user is logged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("authuser"));
    console.log("isLoggedIn call:");
    console.log(user);
    return user !== null ? true : false;
  }
  // Returns true when user is logged in and email is verified
  get isLoggedInNonVerified(): boolean {
    const user = JSON.parse(localStorage.getItem("authuser"));
    console.log("isLoggedIn call:");
    console.log(user);
    return user !== null && !user.emailVerified ? true : false;
  }

  get userId(): string {
    const user = JSON.parse(localStorage.getItem("authuser"));
    return user.id;
  }

  get user(): Object {
    return JSON.parse(localStorage.getItem("authuser"));
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user, isSignUp = false) {
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(
    //   `users/${user.uid}`
    // );

    //if (!isSignUp) {
    //this.setOrDestroySession(user);
    //}
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      role: Roles.subscriber
    };
    // return userRef.set(userData, {
    //   merge: true
    // });
  }

  // Sign out
  SignOut(redirect = true) {
    localStorage.removeItem("authuser");
    localStorage.removeItem("user");
    if (redirect) {
      this.navController.navigateRoot(["search-board"]);
    }
  }

  // setOrDestroySession(user) {
  //   if (user) {
  //     this.userData = user;
  //     const userRef = this.afs.collection("users").doc(`${user.uid}`).ref;
  //     userRef
  //       .get()
  //       .then(function (doc) {
  //         if (doc.exists && doc.data().role === Roles.subscriber) {
  //           localStorage.setItem("user", JSON.stringify(user));
  //           JSON.parse(localStorage.getItem("user"));
  //           console.log("Document data:", doc.data());
  //         } else {
  //           // doc.data() will be undefined in this case
  //           console.log("No such document!");
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log("Error getting document:", error);
  //       });
  //   } else {
  //     localStorage.setItem("user", null);
  //     JSON.parse(localStorage.getItem("user"));
  //   }
  // }
}
