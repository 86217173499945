import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(private loadingController: LoadingController) { }

  async show(message = "") {
    const loading = await this.loadingController.create({
      message: message,
      translucent: true,
      cssClass: "custom-class custom-loading"
    });
    return await loading.present();
  }
  hide() {
    console.log("going to close it");
    this.loadingController.dismiss();
  }
}
